// MethodTimeSlotSettings.tsx
import {
  Banner,
  Button,
  InlineStack,
  Layout,
  BlockStack,
  Text,
} from '@shopify/polaris'
import { useFormContext, useFieldArray } from 'react-hook-form'
import { FormSchema } from './MethodForm/schema'
import { TimeSlotGroup } from './MethodTimeSlotGroup'
import { TIME_GROUP_TEMPLATE } from '@/common/constants'

export const MethodTimeSlotSettings = () => {
  const { watch, control } = useFormContext<FormSchema>()
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'timeSlots',
  })

  const timeEnabled = watch('timeEnabled')
  const enabledDays = watch('enabledDays')

  if (!timeEnabled) {
    return null
  }

  const handleCreateTimeSlot = () => {
    append({
      ...TIME_GROUP_TEMPLATE,
      days: enabledDays || [],
    })
  }

  return (
    <Layout.AnnotatedSection
      id="timeSlots"
      title="Time slots"
      description={
        <BlockStack gap="200">
          <Text variant="bodyMd" as="p">
            Time slots allow you to create time slot groups and assign them to
            specific days.
          </Text>
          <Text variant="bodyMd" as="p">
            You can add individual time slots to your group, or use the time
            slot generator to create them in bulk.
          </Text>
          <Text variant="bodyMd" as="p">
            You can delete a time group by clearing all existing time slots
            using the text button at the bottom of the group. Once the times
            have been removed, the option to delete the group will appear.
          </Text>
        </BlockStack>
      }
    >
      {fields.length === 0 ? (
        <Banner
          title="Time is active, but no time slots have been created"
          action={{ content: 'Add time group', onAction: handleCreateTimeSlot }}
          secondaryAction={{
            content: 'Learn more',
            url: 'https://www.shopside.com.au/bloom-article/getting-started-with-bloom',
            target: '_blank',
          }}
          tone="warning"
        >
          <p>Add at least one group of time slots to get started.</p>
        </Banner>
      ) : (
        <BlockStack gap="400">
          {fields.map((field, index) => (
            <TimeSlotGroup
              key={field.id}
              timeSlotIndex={index}
              removeGroup={() => {
                remove(index)
              }}
            />
          ))}
          <InlineStack align="end">
            <Button onClick={handleCreateTimeSlot}>
              Add another time group
            </Button>
          </InlineStack>
        </BlockStack>
      )}
    </Layout.AnnotatedSection>
  )
}
