import {
  createRootRouteWithContext,
  Outlet,
  ScrollRestoration,
  Link,
} from '@tanstack/react-router'
import { TanStackRouterDevtools } from '@tanstack/router-devtools'
import { useSuspenseQuery, type QueryClient } from '@tanstack/react-query'
import { NavMenu } from '@shopify/app-bridge-react'
import { settingsQueryOptions } from '@/common/queries'
import { useBeacon } from '@/hooks/useBeacon'
import { useEffect } from 'react'
import { Footer } from '@/components/shared/Footer'

export const Route = createRootRouteWithContext<{
  queryClient: QueryClient
}>()({
  component: Component,
})

function Component() {
  const { data: settings } = useSuspenseQuery(settingsQueryOptions())

  const { registerBeacon, populateBeacon } = useBeacon()

  useEffect(() => {
    const initBeacon = async () => {
      try {
        await registerBeacon()
        await populateBeacon()
      } catch (error) {
        console.error('Failed to initialize beacon:', error)
      }
    }

    initBeacon()
  }, [])

  return (
    <>
      <NavMenu>
        <Link to="/" rel="home">
          Dashboard
        </Link>
        <Link to="/orders">Upcoming Orders</Link>
        {settings.methods.map((method) => (
          <Link key={method.uuid} to={`/method/${method.uuid}`}>
            {method.name}
          </Link>
        ))}
        <Link to="/overrides">Overrides</Link>
        <Link to="/settings">Settings</Link>
        <Link to="/display">Display</Link>
        <Link to="/localization">Localization</Link>
      </NavMenu>
      <ScrollRestoration />
      <Outlet />
      <Footer />
      {import.meta.env.DEV && <TanStackRouterDevtools />}
    </>
  )
}
