// Convert a number of minutes to a time past midnight
export const minutesToTime = (minutes = 10) => {
  const midday = 720
  const isMidnight = (val: number) => val === 0 || val === 1440
  const suffix = minutes < midday || isMidnight(minutes) ? 'am' : 'pm'
  const rawHour = Math.trunc(minutes / 60)

  const formattedHour = (() => {
    const hour = rawHour % 12

    if (isMidnight(hour)) {
      return '12'
    }

    return String(hour)
  })()

  const min = minutes % 60 === 0 ? '00' : minutes % 60

  return `${formattedHour}:${min} ${suffix}`
}

const formatTimeSlots = (
  minutes: number,
  interval: number,
  limit: number | null
) => {
  return {
    from: {
      minutes,
      time: minutesToTime(minutes),
    },
    to: {
      minutes: minutes + interval,
      time: minutesToTime(minutes + interval),
    },
    limit,
  }
}

// Generate time slots between two times
export const generateTimeSlots = (
  from: number,
  to: number,
  interval = 30,
  limit: number | null = null
) => {
  const dayInMinutes = 1440

  let totalMinutes = 0
  let timeSlots = []

  // We're going past midmight
  if (from < to) {
    totalMinutes = to - from
  } else {
    totalMinutes = dayInMinutes - Math.abs(to - from)
  }

  timeSlots = [...Array(Math.floor(totalMinutes / interval)).keys()].map(
    (val) => {
      const minutesPastFrom = from + interval * val
      const adjustedMinutes = minutesPastFrom % dayInMinutes
      return adjustedMinutes
    }
  )

  return timeSlots.map((timeSlot) => {
    const formattedTimeSlot = formatTimeSlots(timeSlot, interval, limit)
    return formattedTimeSlot
  })
}

export const generateTimeSelectOptions = (interval: number) => {
  const times = 60 / interval
  let start = 0

  const defaultTimeSlots = [
    {
      value: 0,
      label: minutesToTime(start),
    },
  ]

  const generatedTimeSlots = Array(Math.round(24 * times))
    .fill(0)
    .map(() => {
      start = start + interval

      return {
        value: start,
        label: minutesToTime(start),
      }
    })

  return [...defaultTimeSlots, ...generatedTimeSlots]
}

export const isValidHex = (color: string) => {
  if (!color || typeof color !== 'string') return false

  // Validate hex values
  //if (color.substring(0, 1) === '#') color = color.substring(1)

  return /^#([a-fA-Z0-9]{6}|[a-fA-Z0-9]{3})$/.test(color)
}

export function padTo2Digits(num: number) {
  return num.toString().padStart(2, '0')
}

export function convertTimeToYYYYDDMM(date: Date) {
  const year = date.getFullYear()
  const month = padTo2Digits(date.getMonth() + 1)
  const day = padTo2Digits(date.getDate())

  return `${year}-${month}-${day}`
}

export const buildAdminUrl = (oldUrl: string) => {
  if (oldUrl.startsWith('https://admin.shopify.com/store/')) {
    return oldUrl
  }

  const url = new URL(oldUrl)
  const hostname = url.hostname
  const storeName = hostname.split('.')[0]
  const newPathname = url.pathname.replace('/admin', '')

  const newUrl = `https://admin.shopify.com/store/${storeName}${newPathname}${url.search}`
  return newUrl
}

export const buildAPIUrl = (path: string, searchParams?: URLSearchParams) => {
  const baseURL = import.meta.env.VITE_APP_API_URL
  const url = new URL(`${baseURL}/${path}`)

  if (!searchParams) {
    searchParams = new URLSearchParams()
  }

  searchParams.set('shop', shopify.config.shop)
  url.search = searchParams.toString()

  return url.toString()
}

export const openBeacon = (screen: 'ANSWERS' | 'ASK') => {
  window.Beacon('open')

  if (screen === 'ASK') {
    window.Beacon('navigate', '/ask')
  }

  if (screen === 'ANSWERS') {
    window.Beacon('navigate', '/answers')
  }
}

export {}
