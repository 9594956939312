import { Link, Text } from '@shopify/polaris'

const Footer = () => {
  return (
    <div
      style={{
        width: '100%',
        maxWidth: '680px',
        margin: '2rem auto 1rem auto',
      }}
    >
      <Text as="p" alignment="center">
        Proudly made and supported by <Link target="_blank" url="https://www.shopside.com.au/">Shopside</Link> in Brisbane, Australia
      </Text>
    </div>
  )
}

export { Footer }
