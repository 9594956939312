import { createFileRoute } from '@tanstack/react-router'
import { useState } from 'react'

import {
  Layout,
  Banner,
  Text,
  Button,
  ButtonGroup,
  BlockStack,
  Card,
  MediaCard,
  List,
  Icon,
  InlineStack,
  Box,
  Image,
} from '@shopify/polaris'

import { ThumbsUpIcon } from '@shopify/polaris-icons'

import { PageFrame } from '@/components/shared/PageFrame'
import SetupBanner from '@/components/shared/SetupBanner'

// Utils
import { useSuspenseQuery } from '@tanstack/react-query'
import { settingsQueryOptions } from '@/common/queries'
import useMutateSettings from '@/hooks/useMutateSettings'
import { openBeacon } from '@/common/functions'

export const Route = createFileRoute('/')({
  loader: async ({ context: { queryClient }, route: { path } }) => {
    try {
      // Pre-fetch data
      Promise.all([queryClient.ensureQueryData(settingsQueryOptions())])
    } catch (error) {
      console.error('Error in page loader.', path, error)
      throw error
    }
  },
  pendingComponent: Pending,
  component: Page,
})

function Page() {
  const [hideThemeMessage, setHideThemeMessage] = useState(false)

  const { data: settings } = useSuspenseQuery(settingsQueryOptions())

  const settingsMutation = useMutateSettings()

  // TODO: Don't love this
  const { askedForReview, enabled, themeVersion, methods, onboarded, themeId } =
    settings

  const mentionAppBlock =
    enabled && !onboarded && !hideThemeMessage && themeVersion === '2.0'

  return (
    <PageFrame title={`Bloom: Delivery Date & Pickups`}>
      {!mentionAppBlock && (
        <Layout.Section>
          <SetupBanner
            enabled={enabled}
            toggleEnabled={() => {
              settingsMutation.mutate({ enabled: !enabled })
            }}
            isLoading={settingsMutation.isPending}
            themeVersion={themeVersion}
            methods={methods}
          />
        </Layout.Section>
      )}

      {mentionAppBlock && (
        <Layout.Section>
          <Banner
            tone="info"
            title="Check you have placed the Bloom app block using the Shopify theme editor"
            action={{
              content: 'Customize theme',
              url: `//${shopify.config.shop}/admin/themes/${themeId}/editor`,
              external: true,
            }}
            secondaryAction={{
              content: 'Learn more',
              url: 'https://www.shopside.com.au/bloom-article/using-the-bloom-app-block',
              external: true,
            }}
            onDismiss={() => {
              setHideThemeMessage(true)
              settingsMutation.mutate({ onboarded: true })
            }}
          >
            <p>
              We&apos;ve detected that you&apos;re using an OS2.0 theme. You
              will need to add Bloom to your online store via the theme editor
              before it will show up on your storefront.
            </p>
          </Banner>
        </Layout.Section>
      )}

      <Layout.Section variant="oneHalf">
        <BlockStack gap="400">
          <MediaCard
            title="Getting started with Bloom"
            primaryAction={{
              content: 'Learn more',
              url: 'https://www.shopside.com.au/bloom-article/getting-started-with-bloom',
              target: '_blank',
            }}
            description={`Learn how to configure and activate Bloom on your store.`}
          >
            <Image
              alt="A Bloom branded cover image, showing a computer at a desk."
              width="100%"
              height="100%"
              style={{
                objectFit: 'cover',
                objectPosition: 'center',
              }}
              source="/bloom-hero.webp"
            />
          </MediaCard>
          <Card>
            <BlockStack gap="400">
              <BlockStack gap="200">
                <Text variant="headingSm" fontWeight="bold" as="h2">
                  Get help
                </Text>
                <Text variant="bodyMd" as="p">
                  Whether you need help getting setup with Bloom, or you have a
                  request for a new feature, we&apos;re here to help.
                </Text>
                <ButtonGroup fullWidth>
                  <Button url="https://www.shopside.com.au/docs/bloom" target='_blank'>
                    Visit help center
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() => {
                      openBeacon('ASK')
                    }}
                  >
                    Contact support
                  </Button>
                </ButtonGroup>
              </BlockStack>
            </BlockStack>
          </Card>
          {!askedForReview && (
            <Card>
              <BlockStack gap="400">
                <BlockStack gap="200">
                  <InlineStack align="start" gap="200">
                    <Box>
                      <Icon source={() => <ThumbsUpIcon />} />
                    </Box>
                    <Text variant="headingSm" fontWeight="bold" as="h2">
                      Leave us a review
                    </Text>
                  </InlineStack>
                  <Text variant="bodyMd" as="p">
                    If you have a minute, we would love if you could leave us a
                    quick review about your experience with Bloom. It helps
                    other store owners tremendously. We appreciate you!
                  </Text>
                  <Button
                    variant="primary"
                    url="https://apps.shopify.com/bloom-pickup-and-delivery#modal-show=ReviewListingModal"
                    target='_blank'
                  >
                    Write a quick review
                  </Button>
                </BlockStack>
              </BlockStack>
            </Card>
          )}
        </BlockStack>
      </Layout.Section>

      <Layout.Section variant="oneHalf">
        <BlockStack gap="400">
          <Card>
            <BlockStack gap="400">
              <BlockStack gap="200">
                <Text variant="headingSm" fontWeight="bold" as="h2">
                  Frequently asked questions
                </Text>
              </BlockStack>
              <BlockStack gap="200">
                <Text variant="headingSm" fontWeight="medium" as="h3">
                  Why isn&apos;t Bloom showing on my store?
                </Text>
                <Text variant="bodyMd" as="p">
                  Check that Bloom is activated and you have activated at least
                  one delivery method. Ensure you have set up your active days
                  for that method.
                </Text>
                <Text variant="bodyMd" as="p">
                  For OS2.0 themes, ensure you have added the app block to your
                  theme. For vintage themes, check your placements settings.
                </Text>
                <ButtonGroup>
                  <Button
                    variant="plain"
                    target="_blank"
                    url="https://www.shopside.com.au/bloom-article/why-isnt-bloom-showing-on-my-store"
                  >
                    Learn more
                  </Button>
                </ButtonGroup>
              </BlockStack>
              <BlockStack gap="200">
                <Text variant="headingSm" fontWeight="medium" as="h3">
                  Why did I receive an order with no date?
                </Text>
                <List type="number">
                  <List.Item>
                    Check you have set Bloom to be required in your settings.
                  </List.Item>
                  <List.Item>
                    Ensure Bloom is loaded on your cart or product page, so it
                    can force customers to select a date.
                  </List.Item>
                  <List.Item>
                    Check if you are using any dynamic or express checkout
                    buttons, such as “Buy it now”, as Shopify don&apos;t support
                    these methods.
                  </List.Item>
                </List>

                <ButtonGroup>
                  <Button
                    variant="plain"
                    target="_blank"
                    url="https://help.shopify.com/en/manual/online-store/dynamic-checkout/compatibility"
                  >
                    Learn more
                  </Button>
                </ButtonGroup>
              </BlockStack>
            </BlockStack>
          </Card>
        </BlockStack>
      </Layout.Section>

      {/*
      Keep this commented out for now, I'm testing something
      <Layout.Section>
        <Card>
          <BlockStack gap="400">
            <Box
              background="bg-surface-secondary"
              borderRadius="200"
              padding="400"
            >
              <Grid>
                <Grid.Cell
                  columnSpan={{
                    xs: 6,
                    sm: 6,
                    md: 6,
                    lg: 8,
                    xl: 8,
                  }}
                >
                  <BlockStack gap="300" inlineAlign="start">
                    <BlockStack gap="100">
                      <Text variant="headingSm" fontWeight="bold" as="h3">
                        Bloom: Delivery Date & Pickups
                      </Text>
                      <Text variant="bodySm" as="p" tone="subdued">
                        Bloom is a Shopify app that allows you to offer local
                        delivery and pickup options to your customers.
                      </Text>
                    </BlockStack>
                    <Button>Explore now</Button>
                  </BlockStack>
                </Grid.Cell>
              </Grid>
            </Box>
            <Box>
              <Grid>
                <Grid.Cell
                  columnSpan={{
                    xs: 6,
                    sm: 6,
                    md: 4,
                    lg: 4,
                    xl: 4,
                  }}
                >
                  <Box
                    background="bg-surface-secondary"
                    borderRadius="200"
                    padding="400"
                  >
                    <BlockStack gap="300">
                      <Text variant="headingSm" fontWeight="bold" as="h3">
                        Bloom: Delivery Date & Pickups
                      </Text>
                      <Text variant="bodySm" as="p" tone="subdued">
                        Bloom is a Shopify app that allows you to offer local.
                      </Text>
                    </BlockStack>
                  </Box>
                </Grid.Cell>
                <Grid.Cell
                  columnSpan={{
                    xs: 6,
                    sm: 6,
                    md: 4,
                    lg: 4,
                    xl: 4,
                  }}
                >
                  <Box
                    background="bg-surface-secondary"
                    borderRadius="200"
                    padding="400"
                  >
                    <BlockStack gap="300">
                      <Text variant="headingSm" fontWeight="bold" as="h3">
                        Bloom: Delivery Date & Pickups
                      </Text>
                      <Text variant="bodySm" as="p" tone="subdued">
                        Bloom is a Shopify app that allows you to offer local.
                      </Text>
                    </BlockStack>
                  </Box>
                </Grid.Cell>
                <Grid.Cell
                  columnSpan={{
                    xs: 6,
                    sm: 6,
                    md: 4,
                    lg: 4,
                    xl: 4,
                  }}
                >
                  <Box
                    background="bg-surface-secondary"
                    borderRadius="200"
                    padding="400"
                  >
                    <BlockStack gap="300">
                      <Text variant="headingSm" fontWeight="bold" as="h3">
                        Bloom: Delivery Date & Pickups
                      </Text>
                      <Text variant="bodySm" as="p" tone="subdued">
                        Bloom is a Shopify app that allows you to offer local.
                      </Text>
                    </BlockStack>
                  </Box>
                </Grid.Cell>
              </Grid>
            </Box>
          </BlockStack>
        </Card>
      </Layout.Section> */}
    </PageFrame>
  )
}

function Pending() {
  return (
    <PageFrame title={`Bloom: Delivery Date & Pickups`}>
      <Layout.Section>
        <Box
          minHeight="128px"
          background="bg-fill-tertiary"
          borderRadius="200"
        ></Box>
      </Layout.Section>
      <Layout.Section variant="oneHalf">
        <BlockStack gap="400">
          <Box
            minHeight="144px"
            background="bg-fill-tertiary"
            borderRadius="200"
          ></Box>
          <Box
            minHeight="136px"
            background="bg-fill-tertiary"
            borderRadius="200"
          ></Box>
          <Box
            minHeight="156px"
            background="bg-fill-tertiary"
            borderRadius="200"
          ></Box>
        </BlockStack>
      </Layout.Section>
      <Layout.Section variant="oneHalf">
        <Box
          minHeight="396px"
          background="bg-fill-tertiary"
          borderRadius="200"
        ></Box>
      </Layout.Section>
    </PageFrame>
  )
}
